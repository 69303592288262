import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ProgressBar,
  Row,
} from 'react-bootstrap';
import {
  Link,
  useNavigate,
} from 'react-router-dom';

import * as config from '../config.js';
import { SideBarMedia } from '../controls/sidebar-media.jsx';
import * as config from '../config.js';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
} from '../libs/contexts.js';
import {
  BaseDesktopFaq,
  BaseDesktopFaqTrigger,
  BaseDesktopFooter,
  BaseDesktopHeader,
  BaseDesktopMenu,
  BaseDesktopPremiumBox,
  BaseDesktopProgressBar,
  BaseDesktopSidebar,
} from './base-desktop.jsx';

export const FlatDesktopLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);
    const { branding } = useContext(BrandingContext);

    return (

        <>
            <div className="content-container">
                <FlatDesktopMenu />
                <Container fluid>
                    <Row>
                        <Col className="desktop-area">
                            <Container fluid>
                                <Row>
                                    <Col className={"yes" === layout.iframeVisible ? "offset-xl-1" : "offset-lg-2 offset-xl-3"}>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <FlatDesktopHeader />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {"yes" === layout.faq ?
                                                        <FlatDesktopFaq />
                                                        :
                                                        <>
                                                            {"jump" === layout.specialView ?
                                                                <p>We are getting your quote ready, please hold...</p>
                                                                :
                                                                <>
                                                                    {"steady" === branding.progressMode &&
                                                                        <FlatDesktopProgressBar />
                                                                    }
                                                                    <MyOutlet></MyOutlet>
                                                                </>
                                                            }</>

                                                    }

                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    {"yes" !== layout.iframeVisible &&
                                        <Col className="col-lg-4 col-md-5 col-xl-3">
                                            {"jump" !== layout.specialView && !["thankyou", "opportunities", "consent", "end"].includes(layout.currentScreen) &&
                                                <FlatDesktopSidebar />
                                            }
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            {"end" !== layout.currentScreen &&
                <FlatDesktopFooter />
            }


        </>
    )


}

export const FlatDesktopFaq = () => {

    return (
        <>
            <BaseDesktopFaq />
        </>
    );
}

export const FlatDesktopFooter = () => {

    return (
        <>
            <BaseDesktopFooter />
        </>
    );
}


export const FlatDesktopHeader = () => {

    const { branding } = useContext(BrandingContext);


    return (
        <>
            <div className="broker-logo">
                <a target="_blank" href={branding.website} rel="noopener noreferrer">
                    <Image className="broker-logo" src={config.assets + '/logo.png'} />
                </a>
            </div>
            <Container>
                <Row className="mt-2 mb-3">

                </Row>
            </Container>


        </>
    );
}


export const FlatDesktopMenu = () => {

    const { layout } = useContext(LayoutContext);
    const { session } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);

    return (
        <Container fluid className="menu">
            <Row> 
                <Col className='col-md-10 desktop-menu text-end'>
                    {layout.phone ?
                        <>Questions? {layout.phone}</>
                        :
                        <>&nbsp;</>
                    }
                </Col>
                <Col className='col-md-2 desktop-menu'>
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-0">
                <Col className="px-0 mx-0">
                    {"jump" !== layout.specialView && !['policy', 'thankyou', 'opportunities'].includes(layout.currentScreen) &&
                        <>
                            <BaseDesktopProgressBar />
                        </>
                    }
                </Col>
            </Row>
        </Container>
    );

}

export const FlatDesktopProgressBar = () => {

    const { layout } = useContext(LayoutContext);
    const [progress, setProgress] = useState();
    const [color, setColor] = useState('primary');
    const [isVisible, setIsVisible] = useState(true);

    const { branding } = useContext(BrandingContext);

    useEffect(() => {

        if (layout.currentScreen && layout.screenSequence) {
            let screenCount = layout.screenSequence.length;
            let screenIndex = 0;
            for (let i = 0; i < screenCount; i++) {
                if (layout.currentScreen === layout.screenSequence[i]) {
                    screenIndex = i + 1;
                }
            }
            let screenDiv = screenIndex / (screenCount);
            screenDiv = Math.ceil(100 * screenDiv);
            screenDiv = Math.round(screenDiv / 10) * 10;
            if (screenDiv > 90) {
                screenDiv = 95;
            }
            let newProgress = screenDiv;
            setProgress(newProgress);
        }

    }, [layout.currentScreen, layout.screenSequence]);

    useEffect(() => {

        if ((layout.quoterView && "needs" === layout.quoterView) || 'policy' === layout.currentScreen || 'consent' === layout.currentScreen) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }

    }, [layout.quoterView, layout.currentScreen]);


    useEffect(() => {


        if (progress <= 10) {
            setColor('primary');
        } else {
            if (progress > 10 && progress <= 40) {
                setColor('info');
            } else {
                if (progress > 40 && progress <= 80) {
                    setColor('warning');
                } else {
                    setColor('success');
                }
            }
        }

    }, [progress]);

    return (
        <>
            {isVisible && progress && progress >= 1 &&
                <>
                    <ProgressBar className="full-square" title="If you complete the process, you will be insured in a matter of minutes" animated variant={color} now={progress} label={`You are ${progress}% there!`} />
                </>

            }
        </>
    )

}

export const FlatDesktopSidebar = () => {

    const { branding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <BaseDesktopPremiumBox />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>

                    <Card className="mt-4 border-0">
                        <div className="d-none d-lg-block d-xl-block d-xxl-block mx-auto">
                            <SideBarMedia />
                        </div>
                        <blockquote className="blockquote text-center text-muted fst-italic">
                            <p>{branding.tagline}</p>
                        </blockquote>

                        <Card.Body className="px-0">
                            <Container fluid>
                                <Row className='mb-2'>
                                    <Col className="text-center">
                                        {layout.emailHref ?
                                            <a href={layout.emailHref.href}>{layout.email}</a>
                                            :
                                            <>{layout.email}</>
                                        }
                                    </Col>
                                </Row>

                                <Row className='text-center'>
                                    <Col className='px-0'>
                                        <BaseDesktopFaqTrigger />
                                    </Col>
                                </Row>

                            </Container>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Container>
    );

}
